.skill-levels {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.skill-levels-name {
    width: 25rem;
    margin-right: 10px;
    font-weight: bold;
}

.skill-levels-progress {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    height: 40px;
    background-color: #fafafa;
    border-radius: 0px;
    margin-right: 10px;
    margin-left: 10px;
}

.skill-levels-label {
   white-space: nowrap;
   text-align: center;
   font-weight: bold;
}


