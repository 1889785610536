.single-album-area {
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  margin-bottom: 30px;
}
.single-album-area .album-thumb {
  position: relative;
  z-index: 1;
}
.single-album-area .album-thumb .album-price p {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #fff;
  margin-bottom: 0;
  line-height: 1;
  padding: 5px 15px;
  border-radius: 15px;
  z-index: 20;
}
.single-album-area .album-thumb .play-icon a {
  color: #fff;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.single-album-area img {
  width: 100%;
  height: 16rem;
  object-fit: contain;
  object-position: center;
}
.single-album-area .album-info {
  display: block;
  position: relative;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 15px;
}
.single-album-area .album-info h5 {
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap; /* Hindrer teksten i å bryte over flere linjer */
  overflow: hidden; /* Skjuler overflødig tekst */
  text-overflow: ellipsis; /* Viser ellipser (...) der teksten blir for lang */
  max-width: 100%;
}
.single-album-area .album-info p {
  margin-bottom: 0;
  text-align: center;
}
