.kv-ov {
  display: flex;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.kv-ov-empty {
  width: 25rem;
  margin-right: 10px;
  font-weight: bold;
}

.kv-ov-text {
  text-align: center;
  width: 100%;
  height: 40px;
  border-radius: 0px;
  margin-right: 10px;
  margin-left: 10px;
}
