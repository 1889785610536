.row.avis {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

/* Sørger for at tekst-delen kommer først på mindre skjermer */
.about_section .text-first {
  order: 1;
}

@media (min-width: 992px) {
  .about_section .text-first {
    order: 0; /* Tilbakestill rekkefølgen for store skjermer */
  }

  .about_taital_main {
    width: 100%;
    padding-left: 2rem;
    padding-top: 1rem;
  }

  .row.avis {
  display: flex;
  flex-wrap: wrap;
}
}

.about_taital_main {
  width: 100%;
  padding-left: 1rem;
  padding-top: 2rem;
}

.padding_right_0 {
  padding-right: 0px;
  padding-top: 1rem;
}

.about_taital {
  width: 100%;
  float: left;
  font-size: 2rem;
  color: #1f1f1f;
  font-weight: bold;
}

.about_text {
  width: 100%;
  float: left;
  font-size: 1rem;
  color: #1f1f1f;
  margin: 0px;
  padding-top: 0rem;
}

.readmore_bt {
  width: 10rem;
  float: left;
  margin-top: 2rem;
}

.readmore_bt a {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #ffffff;
  background-color: #000d10;
  text-align: center;
  padding: 10px 0px;
  border-radius: 0px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}

.readmore_bt a:hover {
  color: #ffffff;
  background-color: #2b2278;
}

.about_img {
  height: 100%;
  width: 100%;
  float: left;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
}
