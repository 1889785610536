.blog_item {
  margin-bottom: 2rem;
}

.feature-img {
  display: flex;
  justify-content: center; /* Sentrerer barn-elementer horisontalt */
  align-items: center; /* Sentrerer barn-elementer vertikalt */
  height: 100%; /* Sett høyden etter behov */
}
.single-post p {
  color: #080808;
}
.blog_item_img {
  position: relative;
}

.card-img-1 {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  object-position: 50% 25%;
}

.card-img-2 {
  width: 100%;
  height: 25rem;
  object-fit: cover;
  object-position: 50% 63%;
}

.blog_item_img .blog_item_date {
  position: absolute;
  bottom: -10px;
  left: 10px;
  display: block;
  color: #fff;
  background-color: #3e3e3e;
  padding: 8px 15px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .blog_item_img .blog_item_date {
    bottom: -20px;
    left: 40px;
    padding: 13px 30px;
  }
}

.blog_item_img .blog_item_date h3 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .blog_item_img .blog_item_date h3 {
    font-size: 30px;
  }
}

.blog_item_img .blog_item_date p {
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
}

@media (min-width: 768px) {
  .blog_item_img .blog_item_date p {
    font-size: 18px;
  }
}

.blog_details {
  padding: 30px 0 5px 10px;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}

@media (min-width: 768px) {
  .blog_details {
    padding: 60px 30px 5px 35px;
  }
}

.blog_details p {
  margin-bottom: 30px;
}

.blog_details a {
  color: #080808;
}

.blog_details a:hover {
  color: #670000;
}

.blog_details h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .blog_details h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

.single-post-area .blog_details {
  box-shadow: none;
  padding: 0;
}

.single-post-area .blog_details {
  padding-top: 26px;
}

single-post-area .blog_details p {
  margin-bottom: 20px;
  font-size: 15px;
}

.blog-info-link li {
  float: left;
  font-size: 14px;
  list-style-type: none;
}

.blog-info-link li a {
  color: #999999;
}

.blog-info-link li i,
.blog-info-link li span {
  font-size: 13px;
  margin-right: 5px;
}

.blog-info-link li::after {
  content: "|";
  padding-left: 10px;
  padding-right: 10px;
}

.blog-info-link li:last-child::after {
  display: none;
}

.blog-info-link::after {
  content: "";
  display: block;
  clear: both;
  display: table;
}
