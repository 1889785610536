.accordion {
    margin-top: 50px;
}
.accordion-button {
    font-weight: bold;
    background-color: #fafafa; /* Lys grå bakgrunn */
    color: rgb(0, 0, 0); /* Svart tekst */
}

.accordion-button:not(.collapsed){
    font-weight: bold;
    background-color: #fafafa; /* Lys grå bakgrunn */
    color: rgb(0, 0, 0); /* Svart tekst */
}

.accordion-body {
    background-color: #fafafa; /* Lys grå bakgrunn */
    color: rgb(0, 0, 0); /* Svart tekst */
}

.accordion-body:not(.collapse) {
    background-color: #fafafa; /* Lys grå bakgrunn */
    color: rgb(0, 0, 0); /* Svart tekst */
}
