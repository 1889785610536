.hoved {
  margin-bottom: 1rem;
}
.download-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.p-heading {
  margin-bottom: 2rem;
  text-align: cent;
  margin-left: 2rem;
  margin-right: 2rem;
}

.download-item span {
  order: -1; /* Plasserer navnet over inputfeltet i mobilvisning */
}

.download-description {
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
}

.div-knapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Legg til denne linjen */
}

.sjekkeknapp {
  margin-top: 0.5rem;
}

.lasteknapp {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.inputpw {
  margin-top: 0.5rem;
  width: 15rem;
}

.feilmelding {
  color: red;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
}

@media (min-width: 600px) {
  .hoved {
    margin-bottom: 0;
  }

  .download-item {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .download-item span {
    align-items: flex-start;
    order: 0;
  }
  .download-description {
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .div-knapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 1rem;
    width: auto;
  }
  .sjekkeknapp {
    margin-top: 0rem;
  }

  .lasteknapp {
    margin-top: 0rem;
    margin-left: 0.5rem;
  }

  .inputpw {
    margin-top: 0rem;
    width: 15rem;
  }
  .filnavn {
    align-items: flex-start;
    margin-right: 0rem;
    margin-left: 1rem;
  }
  .feilmelding {
    color: red;
    margin-bottom: 0rem;
    margin-top: 0rem;
    margin-left: 0.5rem;
  }
}
