.portfolio-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem;
}
.portfolio-item .caption {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 37, 41, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.portfolio-item .caption .caption-content {
  color: #fff;
  margin: auto 2rem 2rem;
}
.portfolio-item .caption .caption-content h2, .portfolio-item .caption .caption-content .h2 {
  font-size: 0.8rem;
  text-transform: uppercase;
}
.portfolio-item .caption .caption-content p {
  font-weight: 300;
  font-size: 1.2rem;
}
@media (min-width: 992px) {
  .portfolio-item {
    max-width: none;
    margin: 0;
  }
  .portfolio-item .caption {
    transition: background-color 0.7s, -webkit-clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out, background-color 0.7s;
    transition: clip-path 0.25s ease-out, background-color 0.7s, -webkit-clip-path 0.25s ease-out;
    -webkit-clip-path: inset(0px);
            clip-path: inset(0px);
  }
  .portfolio-item .caption .caption-content {
    transition: opacity 0.25s;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 5rem;
  }
  .portfolio-item img {
    transition: -webkit-clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out, -webkit-clip-path 0.25s ease-out;
    -webkit-clip-path: inset(-1px);
            clip-path: inset(-1px);
  }
  .portfolio-item:hover img {
    -webkit-clip-path: inset(2rem);
            clip-path: inset(2rem);
  }
  .portfolio-item:hover .caption {
    background-color: rgba(29, 128, 159, 0.9);
    -webkit-clip-path: inset(2rem);
            clip-path: inset(2rem);
  }
}

.service-icon {
  background-color: #fff;
  color: #1D809F;
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.img-fluid {
  object-fit: contain;
}

.portfolio-item, .portfolio-item img {
  margin: 1px;
  padding: 0;
}