body {
  font-family: "Poppins", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.8;
  font-weight: normal;
  background: #fafafa;
  color: gray; }
  body.menytrykket {
    overflow-x: hidden; }
    body.menytrykket #colorlib-aside {
      -moz-transform: translateX(0);
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      width: 270px;
      background: #fff;
      z-index: 999;
      position: fixed; }
    body.menytrykket #colorlib-main, body.menytrykket .colorlib-nav-toggle {
      top: 0;
      -moz-transform: translateX(270px);
      -webkit-transform: translateX(270px);
      -ms-transform: translateX(270px);
      -o-transform: translateX(270px);
      transform: translateX(270px); }

.link {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  color: #ff6768;
}

.link:hover, .link:focus {
  text-decoration: none !important;
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}


a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  color: #ff6768; }
  a:hover, a:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none; }

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  line-height: 1.5;
  font-weight: 400;
  font-family: "Poppins", Arial, sans-serif;
  color: #000; }

.bg-primary {
  background: #ff6768 !important; }

.ftco-section {
  padding: 7em 0;
  margin-left: 1em;
 }

.ftco-no-pt {
  padding-top: 0; }

.ftco-no-pb {
  padding-bottom: 0; }

.heading-section {
  font-size: 28px;
  color: #000; }

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

#colorlib-page {
  width: 100%;
  overflow: hidden;
  position: relative; }

#colorlib-aside {
  padding-top: 3em;
  padding-bottom: 40px;
  padding-left: 3em;
  padding-right: 3em;
  width: 20%;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  z-index: 1001;
  background: #fff;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }
  @media (prefers-reduced-motion: reduce) {
    #colorlib-aside {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  @media (min-width: 992px) {
    #colorlib-aside {
      width: 20%; } }
  @media (max-width: 991.98px) {
    #colorlib-aside {
      width: 270px;
      -moz-transform: translateX(-270px);
      -webkit-transform: translateX(-270px);
      -ms-transform: translateX(-270px);
      -o-transform: translateX(-270px);
      transform: translateX(-270px);
      padding-top: 2em;
      background: rgba(0, 0, 0, 0.04); } }
  #colorlib-aside #colorlib-main-menu ul {
    margin: 0;
    padding: 0; }
    #colorlib-aside #colorlib-main-menu ul li {
      margin: 0 0 10px 0;
      padding: 0;
      list-style: none;
      font-size: 22px;
      font-weight: 500;
      line-height: 1.5; }
      #colorlib-aside #colorlib-main-menu ul li a {
        color: black;
        text-decoration: none;
        position: relative;
        padding: 10px 0;
        font-family: "Poppins", Arial, sans-serif;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s; }
        @media (prefers-reduced-motion: reduce) {
          #colorlib-aside #colorlib-main-menu ul li a {
            -webkit-transition: none;
            -o-transition: none;
            transition: none; } }
        #colorlib-aside #colorlib-main-menu ul li a:after {
          content: "";
          position: absolute;
          height: 2px;
          bottom: 7px;
          left: 0;
          right: 0;
          background-color: #ff6768;
          visibility: hidden;
          -webkit-transform: scaleX(0);
          -moz-transform: scaleX(0);
          -ms-transform: scaleX(0);
          -o-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
        #colorlib-aside #colorlib-main-menu ul li a:hover {
          text-decoration: none;
          color: black; }
          #colorlib-aside #colorlib-main-menu ul li a:hover:after {
            visibility: visible;
            -webkit-transform: scaleX(1);
            -moz-transform: scaleX(1);
            -ms-transform: scaleX(1);
            -o-transform: scaleX(1);
            transform: scaleX(1); }
      #colorlib-aside #colorlib-main-menu ul li.colorlib-active a {
        color: #ff6768; }
        #colorlib-aside #colorlib-main-menu ul li.colorlib-active a:after {
          visibility: visible;
          -webkit-transform: scaleX(1);
          -moz-transform: scaleX(1);
          -ms-transform: scaleX(1);
          -o-transform: scaleX(1);
          transform: scaleX(1); }

#colorlib-main {
  width: 100%;
  float: right;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 40px; }
  @media (prefers-reduced-motion: reduce) {
    #colorlib-main {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  @media (min-width: 992px) {
    #colorlib-main {
      width: 80%; }
    .ftco-section {
      margin-left: 0em;}
 }

.colorlib-nav-toggle {
  cursor: pointer;
  text-decoration: none;
  border: none;
  outline: none;
  background-color: transparent; }
  .colorlib-nav-toggle.active i::before, .colorlib-nav-toggle.active i::after {
    background: #000; }
  .colorlib-nav-toggle.dark.active i::before, .colorlib-nav-toggle.dark.active i::after {
    background: #000; }
  .colorlib-nav-toggle:hover, .colorlib-nav-toggle:focus, .colorlib-nav-toggle:active {
    outline: none;
    border-bottom: none !important; }
  .colorlib-nav-toggle i {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 2px;
    color: #000;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #000;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out; }
    .colorlib-nav-toggle i::before, .colorlib-nav-toggle i::after {
      content: '';
      width: 30px;
      height: 2px;
      background: #000;
      position: absolute;
      left: 0;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s; }
      @media (prefers-reduced-motion: reduce) {
        .colorlib-nav-toggle i::before, .colorlib-nav-toggle i::after {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
  .colorlib-nav-toggle.dark i {
    position: relative;
    color: #000;
    background: #000;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out; }
    .colorlib-nav-toggle.dark i::before, .colorlib-nav-toggle.dark i::after {
      background: #000;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s; }
      @media (prefers-reduced-motion: reduce) {
        .colorlib-nav-toggle.dark i::before, .colorlib-nav-toggle.dark i::after {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }

.colorlib-nav-toggle i::before {
  top: -7px; }

.colorlib-nav-toggle i::after {
  bottom: -7px; }

.colorlib-nav-toggle:hover i::before {
  top: -10px; }

.colorlib-nav-toggle:hover i::after {
  bottom: -10px; }

.colorlib-nav-toggle.active i {
  background: transparent; }

.colorlib-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg); }

.colorlib-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg); }

.colorlib-nav-toggle {
  position: fixed;
  left: -12px;
  top: 0px;
  z-index: 9999;
  cursor: pointer;
  opacity: 1;
  visibility: hidden;
  padding: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }
  @media (prefers-reduced-motion: reduce) {
    .colorlib-nav-toggle {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  @media (max-width: 991.98px) {
    .colorlib-nav-toggle {
      opacity: 1;
      visibility: visible; } }
    
