.skill-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.skill-name {
    width: 25rem;
    margin-right: 10px;
    
}

.skill-progress {
    width: 100%;
    background-color: #e2c5c5;
    border-radius: 0px;
    margin-right: 10px;
    margin-left: 10px;
}

.progress {
    height: 40px;
    border-radius: 0px;
    color: rgb(0, 0, 0);
}

.skill-level {
    white-space: nowrap;
}
