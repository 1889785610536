.img-cover {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.basic-3 {
	padding-top: 0;
	padding-bottom: 0;
}

.basic-3 .h2-heading {
	margin-bottom: 0.75rem;
	text-align: center;
}

.basic-3 .p-heading {
	margin-bottom: 4rem;
	text-align: center;
}

.basic-3 .text-container {
	margin-bottom: 4rem;
}

.basic-3 .image-container {
	margin-bottom: 1rem;
}

/* Hover Animation */
.basic-3 .image-container {
	overflow: hidden;
	margin-right: auto;
	margin-left: auto;
}

.basic-3 .image-container img {
	margin: 0;
	transition: all 0.2s;
}

.basic-3 .image-container:hover img {
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
} 
/* end of hover animation */